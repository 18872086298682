<template>
  <span>
    <label class="switch" :class="size" :for="name">
      <input
        type="checkbox"
        :name="name"
        :id="name"
        :checked="value"
        :disabled="disabled"
        @change="$emit('change', $event.target.checked)"
      />
      <span class="slider" :class="size" />
    </label>
  </span>
</template>

<script>
export default {
  model: {
    event: 'change',
  },
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/toggle-switch.scss";
</style>
